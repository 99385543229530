.ContainerCont {
    background-color: #0d2338;
    padding: 40px 10px;

    @media (max-width: 1200px) {
        position: absolute;
    }

    .title {
        span {
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            color: #FFFFFF;
        }
    }

    .filtros {
        width: 60%;
    }

    .contratos {
        .fila {
            .sub {
                margin-left: 15px !important;
            }

            div:last-child {

                // width: 40% !important;
                span:first-child {
                    width: 50%;
                }

                .yellow {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;
                    color: #F4D06F;
                }

                .green {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;
                    color: #26C485;
                }

                .red {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;
                    color: #B55348;
                }

                .gris {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;
                    color: #B1BBC2;
                }
            }
        }
    }
}

.back {
    display: flex;
    cursor: pointer;

    span {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        color: #FFFFFF;
    }
}

.retiro {
    min-height: 100vh;
    width: 90vw;
    margin: 0 auto;

    .back {
        display: flex;
        cursor: pointer;

        span {
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            color: #FFFFFF;
        }
    }

    .header {
        display: flex;
        justify-content: space-between;
        margin-top: 41px;

        h1 {
            margin: 0;
            font-style: normal;
            font-weight: 700;
            font-size: 32px;
            color: #FFFFFF;
        }

        .btnR {
            padding: 12px;
            background: #FBFBFB;
            border: 1px solid #0D2338;
            border-radius: 8px;
            display: flex;
            align-items: center;
            cursor: pointer;

            span {
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                color: #0D2338;
                margin-left: 10px;
            }
        }
    }
}

.Mretiro {
    .header {
        margin-bottom: 18px;

        span {
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            color: #000000;
            margin-left: 5px;
        }
    }

    p {
        font-style: normal;
        font-weight: 500;
        font-size: 18px !important;
        color: #2D2D2F;
    }

    .section {
        margin: 8px 0;

        h6 {
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            color: #2D2D2F;
            margin: 0;
            margin-bottom: 8px;
        }

        span {
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            color: #2D2D2F;
        }
    }

    .Mbtns {
        display: flex;
        width: 100%;

        div {
            width: 50%;
        }

        div:first-child {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 12px;
            gap: 10px;
            background: #FBFBFB;
            border: 1px solid #0D2338;
            border-radius: 8px;
            margin-right: 10px;
        }

        div:last-child {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 12px;
            gap: 10px;
            background: #F4D06F;
            border-radius: 8px;
        }
    }
}

.detail {
    background: #0d2338;
    min-height: 100vh;
    padding: 50px 100px;

    .body {
        margin-top: 53px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .card {
            width: 624px;

            h1 {
                font-style: normal;
                font-weight: 700;
                font-size: 32px;
                color: #FFFFFF;
            }

            .row {
                display: flex;
                border: 1px solid #FFF;

                .casilla {
                    width: 50%;
                    padding: 8px;
                }

                .casilla:first-child {
                    span {
                        font-style: normal;
                        font-weight: 700;
                        font-size: 16px;
                        color: #FFFFFF;
                    }
                }

                .casilla:last-child {
                    span {
                        font-weight: 400;
                        font-size: 16px;
                        color: #FFFFFF;
                    }
                }
            }
        }
    }
}

.fileUpload {
    padding: 12px;
    gap: 10px;
    width: 100%;
    background: #B4BBC1;
    border: 1px solid #0D2338;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;

    div {
        cursor: pointer;

        img {
            margin-right: 16px;
            cursor: pointer;
        }

        span {
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            color: #0D2338;
        }
    }
}

.fileDetails {
    padding: 12px;
    gap: 10px;
    width: 92%;
    background: #B4BBC1;
    border: 1px solid #0D2338;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;

    div {
        cursor: pointer;

        img {
            margin-right: 16px;
            cursor: pointer;
        }

        span {
            font-style: normal;
            font-weight: 700 !important;
            font-size: 16px;
            color: #0D2338 !important;
        }
    }
}

.w-100 {
    width: 90%;
}