.top-bar {
    z-index: 100;
    background-color: #0d2338;


    /* Estilos para dispositivos pequeños */
    @media (max-width: 1200px) {
        display: block;
        width: 100%;
        position: fixed;
        height: auto;
        text-align: center;
        padding-bottom: 20px;

        .menu-icon {

            position: fixed;
            top: 20px;
            left: 20px;
            cursor: pointer;

            span {
                display: block;
                width: 25px;
                height: 3px;
                background-color: #fff;
                margin-bottom: 5px;
            }
        }

        nav {
            display: block;
        }
    }

    @media (min-width: 1201px) {
        display: none;
    }
}

.sidebar {
    width: 200px;
    z-index: 101;
    height: auto;
    overflow-y: hidden;
    overflow-x: hidden;
    color: #fff;
    top: 0;
    left: 0;
    display: block;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s ease-in-out;
    background-color: #031D2E;
    padding: 40px 30px;
    border-radius: 0px 40px 40px 0px;

    @media (min-width: 1201px) {
        min-width: 200px;
    }

    .itemProfile {
        margin: 40px 34px 0px 34px;
        padding: 15px;
        display: flex;
        align-items: center;
        width: 180px;
    }


    .profileSelect {
        cursor: pointer;
        margin: 40px 34px 0px 34px;
        padding: 15px;
        background: #A3ADBB;
        border-radius: 8px;
        width: 180px;

        img {
            margin-right: 17px;
        }

        span {
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 18px;
            color: #0D2338;
        }
    }

    .list {
        .item {
            cursor: pointer;
            margin: 25px 0;
            padding: 15px;
            display: flex;
            align-items: center;

            img {
                margin-right: 17px;
            }

            span {
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
                color: #FBFBFB;
            }
        }

        .itemSelect {
            cursor: pointer;
            margin: 25px 0;
            padding: 15px;
            background: #A3ADBB;
            border-radius: 8px;
            width: 180px;

            img {
                margin-right: 17px;
            }

            span {
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                line-height: 18px;
                color: #0D2338;
            }
        }
    }

    .logo {
        width: 143px;
        height: 28px;
    }

    nav {
        display: flex;
        align-items: center;

        /* Estilos para los enlaces del menú */
        a {
            margin-bottom: 10px;
            color: #fff;
            text-decoration: none;
        }
    }

    .close {
        display: none;
    }

    .header {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    /* Estilos para dispositivos pequeños */
    @media (max-width: 1200px) {
        transform: translateX(-100%);
        transition: transform 0.3s ease-in-out;

        .header {
            display: flex;
            justify-content: space-between;
        }

        .close {
            display: block;
            cursor: pointer;
            right: 20px;
            position: absolute;
        }

        .logo {
            display: none;
        }

        &.open {
            transform: translateX(0);
        }

        nav {
            display: block;
        }
    }
}