.containerTerms {
    background-color: #0d2338;
    margin: 0 auto;
    padding: 5%;

    @media (max-width:1200px) {
        padding: 90px 50px;
        width: auto;
        position: absolute;
    }

    .titulo-section {
        span {
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            color: #FFFFFF;
        }

        margin-bottom: 50px;
    }

    .terms {
        overflow-y: scroll;

        @media (max-width: 1200px) {
            width: 85vw;
        }

        .term {
            display: flex;
            width: 100%;
            justify-content: space-between;
            border-bottom: 1px solid #8fc7e7;
            cursor: pointer;

            p {
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                color: #FFFFFF;
            }
        }

        .term-textH {
            color: #FFFFFF;
            width: auto;


            @media (max-width:1200px) {
                overflow-wrap: break-word;
                width: 100%;
                overflow-y: hidden;
            }
        }
    }
}