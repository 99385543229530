.general-bodys {
    width: 100%;
    height: 98vh;
    overflow-x: hidden;
    background-color: #3c5265;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @media (max-width: 1200px) {
        display: block;
        padding: 20px;
        width: 90%;
        background-color: #0D2338;
    }
}

.componente-mitad-left {
    width: 20%;
    background-color: #3c5265;
    padding: 0 70px;
    height: 100% !important;

    @media (max-width: 1200px) {
        display: none;
    }

    .logo-register {
        margin-top: 120px !important;
    }

    .espacio-subtitulo {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        cursor: pointer;
        margin-top: 60px;

        @media (max-width:1200px) {
            display: none;
        }

        p {
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            color: #FFFFFF;
            line-height: normal
        }

        .flecha-atras {
            width: 32px;
            height: 32px;
            margin-right: 10px;
        }

    }

    .steps-signUp {

        .step {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            margin: 30px 0;
            cursor: pointer;

            img {
                margin-right: 10px;
            }

            p {
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                color: #FBFBFB;
            }
        }
    }
}

.componente-mitad-SignUp {
    width: 75%;
    position: relative;
    background-color: #0d2338;
    padding-top: 17%;
    height: 100%;

    @media (max-width: 1200px) {
        width: 100%;
    }

    .mobile-signup {
        margin-top: 50px;
        display: block;
        justify-content: center;
        text-align: center;

        @media (min-width: 1200px) {
            display: none;

        }

        .progress {
            display: none;

            @media (max-width: 1200px) {
                display: block;
                margin-top: 30px;
                width: 100%;
            }
        }

        .logo-register {
            @media (min-width: 1200px) {
                display: none;

            }
        }

        .espacio-subtitulo {
            width: auto;
            display: flex;
            justify-content: center;
            align-items: center;

            @media (min-width:1201px) {
                display: none;
            }

            .flecha-atras {
                width: 32px;
                height: 32px;
                margin-right: 10px;
            }

            .titulo-select {
                font-weight: 700;
                font-size: 18px;
                color: #FBFBFB;
                line-height: 20px;
                line-height: normal
            }
        }
    }

    p {
        color: white;
    }

    .titulo-1 {
        font-weight: 500 !important;
        font-size: 18px !important;
        color: #B4BBC1 !important;
    }

    .titulo-select {
        font-weight: 700;
        font-size: 18px;
        color: #FBFBFB;
        line-height: normal;

        @media (max-width: 1200px) {
            font-size: 22px;
            text-align: left;
        }
    }

    .titulo-section {
        font-weight: 700;
        font-size: 24px;
        line-height: 28px;
        font-feature-settings: 'calt' off;
        color: #FFFFFF;
    }

    .text-section {
        font-weight: 500;
        font-size: 18px;
        color: #FFFFFF;
    }

    .titulo-2 {
        font-size: 18px;
        width: 600px;
    }

    .vector-fondo {
        position: absolute;
        top: 0;
        left: -120px;
        height: 100%;
        width: 120%;
    }

    .espacio-info {
        position: absolute;
        left: 200px;
    }

    .formularioPass {
        padding-top: 0 !important;

        .btnPass {
            width: 80%;

            @media (max-width: 1200px) {
                width: 100%;
            }
        }
    }

    .formulario-registro {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 5%;
        background-color: #0d2338;

        @media (max-width: 1200px) {
            padding: 0;

            .check {
                display: block;
                margin-right: 10px;
            }
        }

        .check {
            display: block;
            margin-right: 10px;
        }

        .container-campos {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            column-gap: 12px;

            @media (max-width: 1200px) {
                overflow-y: hidden;
                height: auto;
                grid-template-columns: 1fr;
            }

            .validation-message {
                color: #ba6b63;
                margin: 0;
                font-size: 14px;
            }

            .errorPass {
                color: #ba6b63 !important;
            }

            .label-information {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
            }
        }

        .container-campos div:nth-child(2) {
            grid-column-start: 1;
            grid-column-end: 2;
        }

        .container-business {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            column-gap: 12px;

            @media (max-width: 1200px) {
                overflow-y: hidden;
                height: auto;
                grid-template-columns: 1fr;
            }

            .validation-message {
                color: #ba6b63;
                margin: 0;
                font-size: 14px;
            }

            .errorPass {
                color: #ba6b63 !important;
            }

            .label-information {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
            }
        }

        .dolar {
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            color: #0D2338;
            margin: auto;
        }

        .phone-field {
            display: flex;

        }

        .titulo-2 {
            font-size: 18px;
            width: 400px;
        }

        .part-space {
            display: flex;

            @media (max-width: 1200px) {
                display: block;
            }

            .espacio-input:first-child {
                margin-right: 10px;
            }

            .tooltip {
                width: 15px;
                object-fit: contain;
                margin-top: 18px;
            }
        }

        .espacio-titulo {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            width: 100%;
            margin-top: 60px;

            .titulo-img {
                width: 183px;
                height: 63px;
            }

            .logo-img {
                width: 72px;
                height: 63px;
            }

        }

        .espacio-input {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            position: relative;

            // p {
            //     margin-bottom: 3px !important;
            // }

            .titulo-2 {
                font-size: 18px;
                width: 400px;
            }

            .input {
                width: 400px;
                height: 50px;
                border-radius: 8px;
                outline: none;
                text-decoration: none;
                border: none;
                padding-left: 10px;
            }

            .input-dolar {
                padding-left: 50px;
                width: 361px !important;
            }

            .ojo {
                position: absolute;
                right: 15px;
                bottom: 20px;
                width: 16px;
                object-fit: contain;
                cursor: pointer;
            }

            .phoneInput {
                display: flex;
                flex-direction: row;
                position: relative;
                width: 412px;
                cursor: pointer;

                .code {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    padding: 10px;
                    gap: 10px;
                    background: #fbfbfb;
                    border-radius: 8px;
                    margin-right: 10px;

                    .modalCode {
                        max-height: 100px;
                        overflow-y: auto;
                        background: #fbfbfb;
                        position: absolute;
                        top: 50px;
                        z-index: 2;
                        border-radius: 8px;

                        div {
                            padding: 2px;
                            padding-left: 10px;
                            cursor: pointer;
                            border-radius: 8px;
                        }

                        div:hover {
                            background-color: blanchedalmond;
                        }
                    }
                }
            }
        }

        .boton-continuar-disabled {
            width: 100%;
            height: 42px;
            background-color: #B4BBC1;
            color: #777778;
            font-weight: bold;
            font-size: 16px;
            margin-top: 24px;
            outline: none;
            text-decoration: none;
            border-radius: 8px;
            padding: 0;
            border: none;
            cursor: pointer;
        }

        .boton-continuar-enabled {
            width: 100%;
            height: 42px;
            background-color: #F4D06F;
            color: #0D2338;
            font-weight: bold;
            font-size: 16px;
            margin-top: 24px;
            outline: none;
            text-decoration: none;
            border-radius: 8px;
            padding: 0;
            border: none;
            cursor: pointer;
        }

        .boton-normal {
            width: 100%;
            height: 42px;
            background-color: white;
            color: #0D2338;
            font-weight: bold;
            font-size: 16px;
            margin-top: 24px;
            outline: none;
            text-decoration: none;
            border-radius: 8px;
            padding: 0;
            border: none;
            cursor: pointer;
        }
    }

    .formulario-codigo {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;

        .titulo-2 {
            font-size: 18px;
            width: 400px;
        }

        .texto-error {
            font-size: 14px;
            color: #B55348;
            margin-left: 10px;
        }

        .espacio-titulo {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            width: 100%;
            margin-top: 250px;

            .titulo-img {
                width: 183px;
                height: 63px;
            }

            .logo-img {
                width: 72px;
                height: 63px;
            }

        }

        .espacio-input-codigo {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            position: relative;

            p {
                margin-bottom: 3px !important;
            }

            .input {
                width: 72px;
                height: 72px;
                border-radius: 8px;
                outline: none;
                text-decoration: none;
                font-size: 32px;
                border: none;
                text-align: center;
                margin: 10px;
            }

            .input-error {
                width: 72px;
                height: 72px;
                border-radius: 8px;
                outline: none;
                text-decoration: none;
                font-size: 32px;
                border: 2px solid #B55348;
                text-align: center;
                margin: 10px;
            }

            .input-success {
                width: 72px;
                height: 72px;
                border-radius: 8px;
                outline: none;
                text-decoration: none;
                font-size: 32px;
                border: 2px solid #B7EB06;
                text-align: center;
                margin: 10px;
            }
        }

        .boton-continuar-disabled {
            width: 470px;
            height: 42px;
            background-color: #B4BBC1;
            color: #777778;
            font-weight: bold;
            font-size: 16px;
            margin-top: 24px;
            margin-left: 10px;
            outline: none;
            text-decoration: none;
            border-radius: 8px;
            padding: 0;
            border: none;
            cursor: pointer;
        }

        .boton-continuar-enabled {
            width: 470px;
            height: 42px;
            background-color: #F4D06F;
            color: #0D2338;
            font-weight: bold;
            font-size: 16px;
            margin-top: 24px;
            margin-left: 10px;
            outline: none;
            text-decoration: none;
            border-radius: 8px;
            padding: 0;
            border: none;
            cursor: pointer;
        }

        .boton-reenviar-codigo {
            width: 470px;
            height: 50px;
            background-color: #FBFBFB;
            color: #0D2338;
            font-weight: bold;
            font-size: 16px;
            margin-top: 24px;
            margin-left: 10px;
            outline: none;
            text-decoration: none;
            border-radius: 8px;
            padding: 0;
            border: none;
        }
    }
}

.Overlay {
    position: fixed;
    inset: 0px;
    background-color: rgba(255, 255, 255, 0.4);
}

.term-textH {
    width: 700px;
}

.container-terms {
    overflow-y: auto;
    padding-right: 15px;
    background-color: #0D2338;
}

.container-terms::-webkit-scrollbar {
    width: 8px;
}

.container-terms::-webkit-scrollbar-thumb {
    background-color: #FFFF;
    border-radius: 20px;
    border: 2px solid #f1f2f3;
}

.container-terms::-webkit-scrollbar-thumb:hover {
    background-color: #FFFF;
}

.terms {
    .term {
        display: flex;
        width: 700px;
        justify-content: space-between;
        border-bottom: 1px solid #8fc7e7;
        cursor: pointer;

        p {
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            color: #FFFFFF;
        }

    }

    .term-text {
        width: 700px;
        height: 400px;
        overflow-y: scroll;
    }
}

.modal-terminos {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 0;

    .menu {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 272px;
        height: 500px;
        background-color: #E7E9EB;
        padding-top: 15px;

        .fila-info {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            cursor: pointer;

            .numero-info-inselected {
                color: #0D2338;
                border-radius: 100px;
                border: 1px solid #0D2338;
                font-size: 17px;
                width: 30px;
                height: 30px;
                text-align: center;
                font-weight: bold;
                margin: 8px;
            }

            .numero-info-selected {
                background-color: #0D2338;
                color: white;
                border-radius: 100px;
                font-size: 17px;
                width: 30px;
                height: 30px;
                text-align: center;
                font-weight: bold;
                margin: 8px;
            }

            .texto-info {
                color: #0D2338;
                font-size: 16px;
                font-weight: 700px;
                width: 204px;
            }
        }
    }

    .texto-terms {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 805px;
        height: 500px;
        margin-left: 15px;

        .titulo {
            font-weight: 700;
            font-size: 20px;
            color: #0D2338;
        }

        .subtitulo-espacio {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            width: 750px;

            .subtitulo {
                font-weight: 700;
                font-size: 16px;
                color: #000000;
                white-space: nowrap;
                line-height: normal
            }

            .linea {
                height: 1px;
                background-color: #000000;
                width: 100%;
                margin-left: 5px;
                margin-top: 5px;
            }
        }

        .parrafo {
            width: 785px;
            overflow-y: auto;
            margin-top: 0px;

            .texto-parrafo {
                color: #0D2338;
                font-size: 16px;
                margin-top: 0px;
                padding-right: 60px;
                text-align: justify;
            }

            &::-webkit-scrollbar {
                -webkit-appearance: none ! important;
            }


            &::-webkit-scrollbar:vertical {
                width: 8px ! important;
            }

            &::-webkit-scrollbar-button:increment,
            *::-webkit-scrollbar-button {
                display: none ! important;
            }

            &::-webkit-scrollbar:horizontal {
                height: 5px ! important;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #0D2338 ! important;
                border-radius: 100px ! important;
                border: 1px solid #0D2338 ! important;
            }

            &::-webkit-scrollbar-track {
                border-radius: 10px ! important;
            }
        }
    }
}

.formulario-pago {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 200px;
    width: 440px;

    .espacio-titulo {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        margin-top: 200px;

        .titulo-img {
            width: 183px;
            height: 63px;
        }

        .logo-img {
            width: 72px;
            height: 63px;
        }

    }

    .titulo-1 {
        font-size: 24px;
        font-weight: bold;
        margin-left: 7px;
    }

    .titulo-2 {
        font-size: 18px;
        margin-left: 7px;
    }

    .linea-check {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        margin: 20px 0px;
        width: 390px;

        .check {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 24px;
            width: 24px;
            z-index: 2;
        }

        .check-mark {
            position: absolute;
            top: 0;
            left: 0;
            height: 24px;
            width: 24px;
            background-color: #0D2338;
            border-radius: 8px;
            border: 1.5px solid #fff;
            cursor: pointer;
        }

        input:checked~.check-mark {
            background-color: #fff;
        }

        .check-mark:after {
            content: "";
            position: absolute;
            display: none;
        }

        input:checked~.check-mark:after {
            display: block;
        }

        .check-mark:after {
            left: 9px;
            top: 5px;
            width: 5px;
            height: 10px;
            border: solid #0D2338;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }

        .texto {
            font-size: 20px;
            margin-left: 35px;
            margin-top: 2px;
        }
    }

    .boton-continuar-enabled {
        width: 440px;
        height: 42px;
        background-color: #F4D06F;
        color: #0D2338;
        font-weight: bold;
        font-size: 16px;
        margin-left: 10px;
        margin-right: 20px;
        outline: none;
        text-decoration: none;
        border-radius: 8px;
        padding: 0;
        border: none;
        cursor: pointer;
    }

    .boton-continuar-disabled {
        width: 440px;
        height: 42px;
        background-color: #B4BBC1;
        color: #777778;
        font-weight: bold;
        font-size: 16px;
        margin-left: 10px;
        margin-right: 20px;
        outline: none;
        text-decoration: none;
        border-radius: 8px;
        padding: 0;
        border: none;
        cursor: pointer;
    }
}

.modal-cuenta-creada {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 405px;

    .fila-titulo {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 405px;

        .check-img {
            width: 19px;
            object-fit: contain;
            margin: 5px;
        }

        .titulo {
            font-size: 18px;
            font-weight: bold;
            margin: 5px 0px;
        }
    }

    .texto {
        font-size: 16px;
    }

    .boton {
        width: 405px;
        height: 50px;
        background-color: #B7EB06;
        color: #0D2338;
        font-weight: bold;
        font-size: 16px;
        margin-left: 10px;
        margin-right: 20px;
        outline: none;
        text-decoration: none;
        border-radius: 8px;
        padding: 0;
        border: none;
        cursor: pointer;
    }
}

.aceptar-terms {
    width: 100%;
    margin-top: 20px;

    @media (max-width: 1200px) {
        padding-left: 0;
        padding-bottom: 100px;
    }

    .checks {
        margin-top: 5px;

        .linea-check {
            position: relative;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            margin-left: 0px;
            width: 390px;

            .check {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 24px;
                width: 24px;
                z-index: 2;
            }

            .check-mark {
                position: absolute;
                top: 0;
                left: 0;
                height: 24px;
                width: 24px;
                background-color: #0D2338;
                border-radius: 8px;
                border: 1.5px solid #fff;
                cursor: pointer;
            }

            input:checked~.check-mark {
                background-color: #B4BBC1;
                color: red;
            }

            .check-mark:after {
                content: "";
                position: absolute;
                display: none;
            }

            input:checked~.check-mark:after {
                display: block;
            }

            .check-mark:after {
                left: 9px;
                top: 5px;
                width: 5px;
                height: 10px;
                border: solid #0D2338;
                border-width: 0 3px 3px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
            }

            .texto {
                font-size: 16px;
                margin-left: 35px;
                margin-top: 2px;
            }
        }
    }

    .boton-continuar-enabled {
        width: 100%;
        height: 42px;
        background-color: #F4D06F;
        color: #0D2338;
        font-weight: bold;
        font-size: 16px;
        outline: none;
        text-decoration: none;
        border-radius: 8px;
        padding: 0;
        border: none;
        cursor: pointer;
    }

    .boton-continuar-disabled {
        width: 100%;
        height: 42px;
        background-color: #B4BBC1;
        color: #777778;
        font-weight: bold;
        font-size: 16px;
        outline: none;
        text-decoration: none;
        border-radius: 8px;
        padding: 0;
        border: none;
        cursor: pointer;
    }

    .boton-normal {
        width: 100%;
        height: 42px;
        background-color: white;
        color: #0D2338;
        font-weight: bold;
        font-size: 16px;
        outline: none;
        text-decoration: none;
        border-radius: 8px;
        padding: 0;
        border: none;
        cursor: pointer;
    }
}

.justify-text {
    text-align: justify;
    margin-right: 5px;
}