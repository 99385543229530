.loader {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: fade 1.5s ease-in-out infinite;

    @keyframes fade {
        0%,
        100% {
            opacity: 0;
        }

        50% {
            opacity: 1;
        }
    }
}

.imgLogo {
    width: 200px;
    height: 200px;
}