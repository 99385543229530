._chart {
    padding: 30px
}

.container_chart {
    display: flex;
    flex-direction: column;
}

.title {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: var(--white, #FFF);
}

.subtitle {
    margin-top: 12px;
    margin-bottom: 12px;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: var(--white, #FFF);
}

.span {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #BDBDBD
}