.table-wrapper {
  overflow-x: auto;
}

.normal-table {
  width: 100%;
  border-collapse: collapse;
  // Definir variables de colores
  $colorFondo: #212121;
  $colorBorde: #ffffff;

  table {
    width: 100%;
    border-collapse: collapse;
  }

  th :nth-child(0),
  td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  

  th {
    padding: 10px;
    background-color: #3D4C5E;
    text-align: left;
    color: white;
  }

  // Estilos para el cuerpo de la tabla (tbody)
  tbody {

    tr {
      border-bottom: 1 solid #fff;

      td {
        color: $colorBorde;
        padding: 10px;

      }
    }
  }


}

.responsive-table {
  width: 100%;
  border-collapse: collapse;
  padding-left: 10px;
  padding-right: 10px;

  @media (min-width:1201px) {
    display: none;
  }

  th,
  td {
    text-align: left;
    background-color: transparent !important;
    color: #fff;
  }

  .product-info {
    display: flex;
    align-items: center;
    border-bottom: solid 1px;
    padding: 5px 20px;
    margin: 0px 20px;

    span {
      width: 100% !important;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

  }

  .arrow {
    width: 20px;
    height: 20px;
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff;
    transform: rotate(45deg);
    transition: transform 0.3s ease;
    margin: 10px;
    cursor: pointer;

    &.expanded {
      transform: rotate(-135deg);
    }
  }

  .title {
    font-weight: bold !important;
    line-height: 16.1px;
  }

  .details {
    border-top: 1px solid #ddd;

    display: flex;
    flex-direction: row;
    gap: 8px;
    width: 100%;
    background-color: #3D4C5E;
    padding: 20px;
    border-radius: 0px 0px 10px 10px;

    .detail-row {
      display: grid;
      justify-content: space-between;
      width: 100%;

      span {
        padding: 5px;
        font-weight: 400;
        font-size: 24px;
      }

      .currency {
        color: #B2BBC6;
      }
    }
  }

  .desktop-only {
    @media (max-width: 600px) {
      display: none;
    }
  }

  .mobile-only {
    display: none;

    @media (max-width: 600px) {
      display: table-cell;
    }
  }

  .arrow-column {
    width: 30px;
  }
}