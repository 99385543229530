.perfil {
  background-color: #0d2338;
  padding: 40px;

  @media (max-width:1200px) {
    padding: 40px 20px;
    position: absolute;
    margin-top: 50px;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    div:first-child {
      display: flex;
      align-items: center;

      .imageContainer {
        position: relative;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        cursor: pointer;
        overflow: hidden;
        background-color: gray;

        .profileImage {
          object-fit: contain;
          width: 100%;
          height: 100%;
        }

        .notProfileImage {
          width: 80%;
          margin: 0 auto;
        }

        .profileImageHover {
          position: absolute;
          scale: 0.7;
          width: 100%;
          height: 100%;
        }

        .opacity {
          opacity: 0.4;
        }

        .hidden {
          visibility: hidden;
        }
      }

      span {
        margin-left: 16px;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        color: #ffffff;
      }
    }

    .kyc {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 8px 16px;
      cursor: pointer;
      gap: 8px;
      background: #b7eb06;
      position: relative;
      border-radius: 8px;

      .hidden {
        display: none;
      }

      span {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        color: #0d2338;
      }

      .panelKyc {
        position: absolute;
        width: 438px;
        padding: 20px 16px;
        border-radius: 8px;
        background-color: #FBFBFB;
        top: 45px;
        right: 0;
        z-index: 2;
        display: flex;

        img {
          width: 24px;
          height: 24px;
        }

        p {
          margin-top: 0;
        }
      }
    }
  }

  .subHeader {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;

    @media (max-width:1200px) {
      flex-direction: column-reverse;
      text-align: center;
      align-items: center;
    }

    .options {
      display: flex;
      margin-top: 28px;
      gap: 20px;

      @media (max-width:1200px) {
        flex-direction: column;
      }

      .itemI {
        span {
          cursor: pointer;

          font-style: normal;
          font-weight: 700;
          font-size: 18px;
          line-height: 26px;
          color: #fbfbfb;
          border-bottom: 3px solid #eccf82;
        }
      }

      .item {
        span {
          cursor: pointer;
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          color: #fbfbfb;
        }
      }
    }

    .btns {
      display: flex;

      .btn1 {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 12px;
        gap: 10px;
        background: #fbfbfb;
        border: 1px solid #0d2338;
        border-radius: 8px;
        margin-right: 14px;
        cursor: pointer;
      }

      .containerOptions {
        position: relative;

        .btn2 {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 12px;
          gap: 10px;
          background: #fbfbfb;
          border: 1px solid #0d2338;
          border-radius: 8px;
          cursor: pointer;
        }

        .hidden {
          display: none;
        }

        .options {
          display: flex;
          cursor: pointer;
          z-index: 2;
          flex-direction: column;
          align-items: flex-start;
          padding: 0px 15px;
          position: absolute;
          width: 206px;
          right: 0;
          top: 50px;
          background: #fbfbfb;
          border: 1px solid #0d2338;
          border-radius: 8px;

          div {
            margin: 10px 0;

            img {
              margin-right: 15px;
            }
          }
        }
      }
    }
  }

  .formulario-registro {
    display: flex;

    justify-content: flex-start;
    margin-top: 30px;
    width: 100%;
    align-items: center;

    @media (max-width:1200px) {
      justify-content: center;
    }

    .titulo-2 {
      font-size: 18px;
      color: #fff;
      width: 400px;
    }

    .part-space {
      display: flex;
      gap: 10px;

      @media (max-width:1200px) {
        display: block;
      }

      .espacio-input:first-child {
        margin-right: 10px;

        @media (max-width:1200px) {
          margin: 0px;
        }
      }
    }

    .espacio-titulo {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      margin-top: 60px;

      .titulo-img {
        width: 183px;
        height: 63px;
      }

      .logo-img {
        width: 72px;
        height: 63px;
      }
    }

    .phoneInput {
      display: flex;
      flex-direction: row;
      width: 100%;
      cursor: pointer;

      .code {
        display: flex;
        position: relative;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 10px;
        gap: 10px;
        background: #fbfbfb;
        border-radius: 8px;
        margin-right: 10px;
        height: 31px;
        margin-top: 10px;

        .modalCode {
          width: 60px;
          background: #fbfbfb;
          position: absolute;
          top: 50px;
          z-index: 2;
          border-radius: 8px;

          div {
            padding: 10px;
            padding-left: 10px;
            cursor: pointer;
            border-radius: 8px;
          }

          div:hover {
            background-color: blanchedalmond;
          }
        }

        .hidden {
          display: none;
        }
      }
    }

    .espacio-input {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      position: relative;
      width: 100%;

      p {
        margin-bottom: 3px !important;
      }

      .titulo-2 {
        font-size: 18px;
        width: 400px;
      }

      .input {
        width: 100%;
        max-width: 400px;
        height: 50px;

        border-radius: 8px;
        outline: none;
        text-decoration: none;
        border: none;
        margin-top: 10px;
        padding-left: 10px;
      }

      .input-dolar {
        padding-left: 50px;
        width: 90%;
      }

      .dolar {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        color: #0D2338;
        position: absolute;
        top: 57%;
        left: 10px;
        margin: auto;
      }

      .ojo {
        position: absolute;
        right: 15px;
        bottom: 20px;
        width: 16px;
        object-fit: contain;
        cursor: pointer;
      }
    }


  }
}

.deleteM {
  width: 100%;

  .deleteHeader {
    display: flex;
    justify-content: space-between;
    cursor: pointer;

    span {
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 23px;
      color: #0d2338;
    }
  }

  .deleteTitle {
    margin: 24px 0;

    span {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      color: #000000;
    }
  }

  .deleteBody {
    div {
      margin: 10px 0;

      span {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #0d2338;
      }
    }

    .inputA {
      display: flex;
      flex-direction: column;

      input {
        padding: 12px;
        border-radius: 8px;
      }

      span {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        color: #0d2338;
      }
    }
  }
}

.w-100 {
  width: 90%;

  @media (max-width:1200px) {
    width: 90%;
  }
}

.btnFile {
  cursor: pointer;
  width: 100%;
  border-radius: 10px;
  border: 1px solid #FFF;
  padding: 12px 0;
  display: flex;
  justify-content: center;

  input {
    position: absolute;
    visibility: hidden;
  }

  span {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: #FBFBFB;
    margin-left: 14px;
  }
}

.fileUpload {
  padding: 12px;
  gap: 10px;
  width: 440px;
  background: #B4BBC1;
  border: 1px solid #0D2338;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;

  div {
    cursor: pointer;

    img {
      margin-right: 16px;
      cursor: pointer;
    }

    span {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      color: #0D2338;
    }
  }
}

.boton-continuar-disabled {
  width: 100%;
  height: 50px;
  background-color: #b4bbc1;
  color: #777778;
  font-weight: bold;
  font-size: 16px;
  margin-top: 24px;
  outline: none;
  text-decoration: none;
  border-radius: 8px;
  padding: 0;
  border: none;
  cursor: pointer;
}

.boton-continuar-enabled {
  width: 100%;
  height: 50px;
  background-color: #f4d06f;
  color: #0d2338;
  font-weight: bold;
  font-size: 16px;
  margin-top: 24px;
  outline: none;
  text-decoration: none;
  border-radius: 8px;
  padding: 0;
  border: none;
  cursor: pointer;
}

.only-read {
  color: gray;
}

@media (min-width: 1301px) {
  .dolar {
      top: 62% !important;
  }
}