body {
    position: 'absolute';
    z-index: 1000;
}

.onboarding {
    background-color: #3D4C5E;
    display: flex;
    margin-top: 2%;
    justify-content: center;

    @media (max-width:1200px) {
        background-color: transparent;
    }
}

.second-step {
    display: flex;
    width: 100vw !important;
    height: 100vh;

    .left {
        @media (max-width:1200px) {
            display: none;
        }

        height: 120vh;
        padding: 2%;
        align-items: center;
        margin-right: 10px;
        background-color: #546881;
    }

    .right {
        @media (max-width:1200px) {
            display: none;
        }

        width: 100%;
        text-align: center;
        align-items: center;
        display: flex;
        flex-direction: column;
        padding: 0 30px 30px 0;
    }

    .options {
        margin-top: 15%;
        display: flex;
        flex-direction: column;

        .index {
            width: 40px;
            min-width: 40px;
            height: 40px;
            border-radius: 50%;
            border: 1px solid white;
            background: none; // Asegura que no haya fondo dentro del círculo
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 10px;

            span {
                font-size: 24px;
                font-weight: lighter;
                text-align: justify;
            }
        }

        button {
            display: flex;
            align-items: center;
            justify-content: left;
            margin-bottom: 10px;
            border-radius: 8px;
            color: white;
            border-color: white;
            background-color: transparent;
            font-size: 16px;
            line-height: 18.4px;
            margin-top: 20px;
            text-align: justify;
        }

        .active {
            color: #F4D06F;
            border: 1px solid #F4D06F;
        }

    }

    @media (min-width: 1201px) {
        .mobile {
            display: none;
        }
    }

    @media (max-width:1200px) {
        .mobile {
            background-color: #3D4C5E;

            .bottom {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding: 30px 30px 90px 30px;
                height: 25vh;
                background-color: #546881;
                position: absolute;
                bottom: 0;
                text-align: center;
                border-radius: 20px 20px 0 0;

                h1 {
                    font-weight: 700;
                    line-height: 20.7px;
                }

                p {
                    font-weight: 400;
                    line-height: 20.7px;
                }

                .nav-buttons {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .nav-dots {
                        display: flex;
                        gap: 5px;
                    }

                    .dot {
                        width: 15px;
                        height: 15px;
                        border-radius: 50%;
                        background-color: #B1BBC2;
                    }

                    .active {
                        background-color: #F4D06F;
                    }

                }
            }
        }
    }

}

.continue {
    background-color: #F4D06F;
    color: #0D2338;
    font-size: "16px";
    font-weight: 700;
    border-radius: 5px;
    height: 42px;
    width: auto;
    border: 0;
    float: right;
    padding: 0 20px;
    cursor: pointer;

    @media (max-width:1200px) {
        width: 100%;
    }
}

h1 {
    color: white;
    font-weight: 700;
    font-size: 25px;
    line-height: 20.7px;
}

p {
    color: white;
    font-weight: 400;
    font-size: 18px;
    line-height: 16.1px;
}

.image {
    height: 100%;
    justify-content: center;
    align-items: center;
    padding: 10px 100px 0 100px;

    @media (max-width:1200px) {
        padding: 30px 30px 0 30px;
    }

    img {
        object-fit: contain;
        max-width: auto;

    }

    .title {
        color: white;
        font-weight: 700;
        font-size: 18px;
        line-height: 20.7px;
        text-align: justify;
    }

    p {
        color: white;
        font-weight: 400;
        font-size: 18px;
        line-height: 16.1px;
        text-align: justify;
    }

}

.container-step-1 {
    width: 90vw;
    height: 50%;
    margin: 0px 20%;

    @media (max-width:1200px) {
        margin: 0;
    }
}

.first-step {
    background-image: url('../../assets/img/png/LogoCAB.png');
    background-repeat: no-repeat;
    background-size: contain;
    display: grid;
    justify-content: center;
    text-align: center;
    background-color: #0D2338;
    align-content: center;
    padding: 90px;
    height: 50vh;
    margin-bottom: 10px;

    p {
        font-size: 20px;
        padding: 0px 90px;
        text-align: center;
        line-height: 20px;

        @media (max-width:1200px) {
            padding: 0;
        }
    }

    @media (max-width:1200px) {
        padding: 0;
        margin: 0;
        height: 88vh;
    }
}