.container {
  display: flex;
  width: 100%;
  height: 95vh;
  overflow-x: hidden;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: url('../assets/img/svg/Logo_CAB.svg') #0d2338;
  background-repeat: no-repeat;
  background-size: 70%;

  &.vector-left {
    background-position: -50% 100%;
  }

  &.vector-right {
    background-position: 100% -50%;
  }

  @media (max-width: 700px) {
    width: 100% !important;
    background-position: 200% 10% !important;
    background-size: 90%;
  }

  .left-column {
    width: 50%;

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  .right-column {
    width: 50%;
    padding: 50px;

    @media (max-width: 768px) {
      display: none;
    }
  }

  .vector-fondo {
    position: absolute;
    top: 0;
    left: -120px;
    height: 100%;
    width: 120%;
  }

  .titulo-1 {
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .titulo-2 {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .container-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px;
  }

  .back_title {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    cursor: pointer;

    .arrow-back {
      width: 28px;
      height: 28px;
      margin-top: 2px;
      margin-right: 10px;
    }
  }

  .form {
    width: 470px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .link {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    text-decoration-line: underline;
    font-feature-settings: 'calt' off;
    color: #7FC5ED;
    cursor: pointer;
    margin-top: 30px;
  }
}

.logo-header {
  align-self: flex-start;
}

.logo {
  width: 100%;
  // margin-left: 30px;
}

.texto-error{
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #B55348;
}