$primary-text-color: #0D2338;
$secondary-text-color: #6E5E32;

.card-list {
    margin: 2rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    justify-items: center;
    justify-content: center;
}


@media (min-width: 480px) and (max-width: 767px) {
    .card-list {
        grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
    }

    .card {
        max-height: 500px;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .card-list {
        grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
    }

    .card {
        max-height: 500px;
    }
}

.cardCustom {
    border-radius: 8px;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 30px;
    box-sizing: border-box;
    align-items: flex-start;
    font-family: Arial, sans-serif;
    background-repeat: no-repeat !important;
    background-position: 100% 45% !important;

    .title {
        font-weight: 700;
        font-size: 18px;
    }

    .text-custom-card {
        display: block;
        width: 70%;
        color: #6E5E32;
    }
}

.card {
    width: 100%;
    height: 300px;
    max-height: 500px;
    perspective: 1000px;
    position: relative;
    cursor: pointer;

    @media (max-width:1200px) {
        max-width: 400px;
    }

    .front,
    .back {
        border-radius: 8px;
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 30px;
        box-sizing: border-box;
        backface-visibility: hidden;
        transition: transform 0.5s ease;

        .title {
            margin-top: 20px;
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            color: $primary-text-color;
        }

        h6 {
            margin: 0;
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 18px;
            color: $primary-text-color
        }

        span {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            color: $secondary-text-color;
        }
    }

    .customCard {
        background-repeat: no-repeat;
        background-position: 100% 45%;
        align-items: flex-start;
        border-radius: 8px;
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 30px;
        box-sizing: border-box;
    }

    .front {
        background-repeat: no-repeat;
        transform: rotateY(0deg);
        background-position: 100% 45%;
        align-items: flex-start;

        .card-state {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            justify-items: center;
            margin-bottom: 15px;
        }

        .card-state-content {
            display: flex;
            align-items: center;
            margin-top: 5px;
        }

        .point-state {
            width: 15px;
            height: 15px;
            border-radius: 50%;
        }
    }

    .back {
        background-repeat: no-repeat;
        transform: rotateY(180deg);
        background-position: -5% 45%;
        align-items: center;
    }

    &:hover {
        .front {
            transform: rotateY(-180deg);
            transition-delay: .5s;
        }

        .back {
            transform: rotateY(0deg);
            transition-delay: .5s;
        }
    }
}


.card1 {
    background: url('../../../assets/img/svg/backMoney.svg') #FCF0D2;
}

.card2 {
    background: url('../../../assets/img/svg/backMoney1.svg') #ebf5c9;
}

.card3 {
    background: url('../../../assets/img/svg/backMoney2.svg') #c7e4f5;
}

.card4 {
    background: url('../../../assets/img/svg/backMoney4.svg') #d8dbdd;
}

.card5 {
    background: url('../../../assets/img/svg/backMoney5.svg') #98D2A9;
}

.card6 {
    background: url('../../../assets/img/svg/backMoney6.svg') #F4D06F;
}

.btn {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px;
    gap: 10px;
    border: 1px solid #0D2338;
    border-radius: 8px;
    cursor: pointer;
    float: right;
    color: #0D2338;
    width: 200px;
    font-weight: 700;
    background-color: white;
}

.btn1 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px;
    gap: 10px;
    background: #fbfbfb;
    border: 1px solid #0d2338;
    border-radius: 8px;
    margin-right: 14px;
    cursor: pointer;
}

.btn-container {
    display: flex;
    align-items: end;
    justify-content: end;
    width: 100%;
}

.rose-pastel {
    background-color: #fdcae1;
}

.blue-pastel {
    background-color: #84b6f4;
}

.green-pastel {
    background-color: #77dd77;
}

.yellow-pastel {
    background-color: #fdfd96;
}

.red-pastel {
    background-color: #ff6961;
}