.main {
    // width: 100%;
    display: flex;
    position: relative;
    min-height: 100vh;

    main {
        width: 100%;
        padding: 0 30px;
    }

    .soporte {
        position: fixed;
        right: 300px;
        bottom: 60px;
        cursor: pointer;
    }

    .left {
        background-color: #031D2E;
        width: 20%;
        padding: 40px 30px;
        border-radius: 0px 40px 40px 0px;

        .logo {
            width: 143px;
            height: 28px;
            margin-bottom: 50px;
        }

        .profile {
            display: flex;
            align-items: center;
            margin-bottom: 32px;
            cursor: pointer;

            img {
                margin-right: 12px;
            }

            span {
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                color: #FBFBFB;
            }
        }

        .profileS {
            display: flex;
            align-items: center;
            padding: 12px;
            gap: 12px;
            background: #A3ADBB;
            border-radius: 8px;
            cursor: pointer;

            img {
                margin-right: 12px;
            }

            span {
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                color: #0D2338;
            }
        }

        .list {
            .item {
                cursor: pointer;
                margin: 25px 0;
                padding: 15px;
                display: flex;
                align-items: center;

                img {
                    margin-right: 17px;
                }

                span {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 20px;
                    color: #FBFBFB;
                }
            }

            .itemSelect {
                cursor: pointer;
                margin: 34px 0;
                padding: 15px;
                background: #A3ADBB;
                border-radius: 8px;

                img {
                    margin-right: 17px;
                }

                span {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 18px;
                    color: #0D2338;
                }
            }
        }
    }

    .right {
        margin-top: 25px;
        width: 100%;

        @media (max-width: 1200px) {
            margin-top: 90px;
            position: absolute;
        }

        h1 {
            font-weight: 700;
            font-size: 24px;
            line-height: 28px;
            color: #FFFFFF;
        }

        .panelsB {
            display: flex;
            overflow-x: auto;
            overflow-y: hidden;
            min-height: 300px;

            @media (min-width:1201px) {
                margin-left: 20px;
                justify-content: left;
            }

            .bill {
                margin-left: 10px;
                padding: 16px;
                gap: 12px;
                width: 268px;
                height: 184px;
                background: #47586E;
                margin-top: 40px;
                border-radius: 8px;

                @media (max-width: 1500px) {
                    width: auto;
                }

                .bodyW {
                    display: flex;
                    align-items: center;
                    flex-direction: column;

                    h6 {
                        font-style: normal;
                        font-weight: 700;
                        font-size: 32px;
                        line-height: 37px;
                        color: #FFFFFF;
                        margin: 0;
                        margin-bottom: 4px;


                    }

                    span {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 16px;
                        text-align: center;
                        color: #FFFFFF;

                        @media (max-width: 1200px) {
                            font-size: 20px;
                            margin-top: 10px;
                        }
                    }

                    .under {
                        margin-top: 20px;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 22px;
                        text-decoration-line: underline;
                        font-feature-settings: 'calt' off;
                        color: #7FC5ED;
                        cursor: pointer;

                        @media (max-width: 1200px) {
                            font-size: 26px;
                        }
                    }
                }

                .headerW {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin: 20px;

                    div {
                        display: flex;
                        align-items: center;

                        span {
                            font-style: normal;
                            font-weight: 700;
                            font-size: 18px;
                            line-height: 21px;
                            color: #FBFBFB;

                            @media (max-width: 1200px) {
                                font-weight: 600;
                                font-size: 24px;
                            }

                        }

                        img {
                            margin-right: 16px;
                            width: 42px;
                        }
                    }

                    span {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 16px;
                        color: #D9DEE1;

                        @media (max-width: 1200px) {
                            font-size: 20px;
                        }
                    }
                }
            }
        }


        .articles {
            .article {
                display: flex;
                justify-content: space-between;
                border-bottom: 1px solid #FFF;
                padding: 25px;
                margin-right: 20px;

                div {
                    span {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 16px;
                        color: #8E8E8F;
                    }

                    h6 {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 18px;
                        color: #FFFFFF;
                        margin: 10px 0;
                    }

                    p {
                        margin: 0;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 21px;
                        color: #FFFFFF;
                    }
                }

                img {
                    width: 150px;
                    height: 150px;
                    border-radius: 5px;
                }
            }
        }
    }
}

.notifacations {
    // width: 100vw;
    padding: 40px 100px;
    display: block;

    @media (max-width:1200px) {
        position: absolute;
        padding: 50px 50px;
    }

    .title {
        margin-bottom: 50px;

        @media (max-width: 1200px) {
            margin-top: 50px;
        }

        span {
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            color: #FFFFFF;
        }
    }

    .notificationPage {
        border-bottom: 1px solid #5b6671;
        margin-top: 15px;
        padding-bottom: 10px;

        @media (max-width:1200px) {
            width: 80%;
        }

        div {
            display: flex;

            span {
                font-style: normal;
                font-weight: 700;
                font-size: 20px;
                line-height: 23px;
                color: #FFFFFF;
            }
        }

        p {
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            color: #FFFFFF;
            max-width: 616px;
            ;
        }

        span {
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            color: #8E8E8F;
        }
    }
}

.back {
    display: flex;
    cursor: pointer;

    img {
        margin-right: 10px;
    }

    span {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        color: #FFFFFF;
    }
}

.balance {
    background: #0d2338;
    padding-left: 10%;
    padding-top: 53px;
    min-height: 100vh;

    @media only screen and (max-width: 500px) {
        padding-left: 0;
    }

    @media (max-width:1200px) {
        display: block;
    }

    .container {
        display: flex;

        @media (max-width:1200px) {
            flex-direction: column-reverse;
        }


    }

    .left {
        .title {
            display: flex;
            margin-top: 41px;

            img {
                margin-right: 14px;
            }

            span {
                font-style: normal;
                font-weight: 700;
                font-size: 32px;
                color: #FFFFFF;
            }
        }

        .description {
            margin: 0;
            margin-top: 18px;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            color: #FFFFFF;
            position: absolute;
        }

        .banks {
            margin-top: 60px;

            .bank {
                padding: 12px;
                gap: 12px;
                width: 85%;
                height: 151px;
                background: #E6E9EB;
                border-radius: 8px;
                margin-bottom: 12px;

                .bankTitle {
                    display: flex;

                    img {
                        margin-right: 10px;
                    }

                    span {
                        font-style: normal;
                        font-weight: 700;
                        font-size: 24px;
                        color: #0D2338;
                    }
                }

                .bankD {
                    margin-top: 12px;

                    span {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 18px;
                        line-height: 21px;
                        color: #0D2338;
                    }
                }
            }
        }
    }

    .right {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 20%;
        padding-top: 10%;

        @media (max-width:1200px) {
            padding-left: 0;
            width: 90%;
        }

        .panelInfo {
            padding: 12px;
            display: flex;
            gap: 10px;
            background: #7FC5ED;
            align-items: flex-start;
            border-radius: 8px;

            p {
                font-style: normal;
                font-weight: 500;
                font-size: 20px;
                color: #2D2D2F;
                margin: 0;
            }

            span {
                font-weight: 700;
            }
        }

        .description {
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            color: #FBFBFB;
        }

        .btn {
            cursor: pointer;
            margin-top: 50px;
            width: 100%;
            border-radius: 10px;
            border: 1px solid #FFF;
            padding: 12px 0;
            display: flex;
            justify-content: center;

            input {
                position: absolute;
                visibility: hidden;
            }

            span {
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                width: 85%;
                margin-left: 14px;
            }
        }

        .fileUpload {
            padding: 12px;
            margin-top: 50px;
            gap: 10px;
            width: 440px;
            background: #B4BBC1;
            border: 1px solid #0D2338;
            border-radius: 8px;
            display: flex;
            justify-content: space-between;

            div {
                cursor: pointer;

                img {
                    margin-right: 16px;
                    cursor: pointer;
                }

                span {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    color: #0D2338;
                }
            }
        }

        .btnDisable {
            background: #B4BBC1 !important;

            span {
                color: #777778 !important;
            }
        }

        .btnSend {
            cursor: pointer;
            padding: 12px;
            margin-top: 30px;
            gap: 10px;
            background: #F4D06F;
            border-radius: 8px;
            display: flex;
            justify-content: center;

            span {
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                color: #0D2338;
            }
        }
    }
}

.inversions-home {
    display: flex;
    justify-content: left;
    margin-left: 30px;

    .itemI {
        span {
            cursor: pointer;
            margin-right: 32px;
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 21px;
            color: #FBFBFB;
            border-bottom: 3px solid #eccf82;
            padding-bottom: 5px;
        }
    }

    .item {
        span {
            cursor: pointer;
            font-style: normal;
            margin-right: 32px;
            font-weight: 500;
            font-size: 18px;
            color: #FBFBFB;
        }
    }
}

.containerA {
    width: 100vw;
    background: #0d2338;
    padding-left: 190px;
    padding-top: 53px;
    padding-bottom: 53px;

    .article {
        max-width: 900px;

        .headerA {
            h2 {
                font-style: normal;
                font-weight: 700;
                font-size: 32px;
                line-height: 37px;
                color: #FFFFFF;
            }

            h2 {
                color: #FFFFFF;
            }

            p {
                font-style: normal;
                // font-weight: 500;
                font-size: 18px;
                line-height: 21px;
                color: #FFFFFF;
                max-width: 900px;
            }
        }

        .bodyA {
            div {
                display: flex;
                margin-top: 20px;
                border-top: 1px solid #fff;
                padding-top: 20px;

                span:last-child {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    color: #FFFFFF;
                    margin-left: 15px;
                }

                span {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 18px;
                    color: #FFFFFF;
                }
            }

            img {
                margin: 25px 0;
            }

            p {
                font-style: normal;
                // font-weight: 500;
                font-size: 18px;
                line-height: 21px;
                color: #FFFFFF;
            }
        }
    }
}

.pagination-btns {
    margin-right: 110px;
    margin-top: 20px;
    display: flex;
    gap: 8px;
}

.btn-pagination {
    border: none;
    background: none;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
}

.btn-pagination.active {
    background-color: #A3ADBB;
    padding: 4px 8px;
    border-radius: 4px;
    color: #0D2338;
}

.fix-scroll {
    overflow-y: hidden;
}

.container-mobil {
    @media only screen and (max-width: 480px) {
        height: auto !important;
        .fix-scroll {
            overflow-y: auto;
        }
    }
}
