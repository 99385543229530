.modal {
    width: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    height: 100%;

    .btn-disable {
        display: flex;
        cursor: pointer;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 99%;
        padding: 12px;
        gap: 10px;
        background: #B4BBC1;
        border-radius: 8px;

        span {
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            color: #777778;
        }
    }

    .btn {
        display: flex;
        cursor: pointer;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 98%;
        padding: 12px;
        gap: 10px;
        background: #F4D06F;
        border-radius: 8px;

        span {
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            color: #0D2338;
        }
    }

    .contentPay {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        position: fixed;
        top: 15%;
        padding: 24px;
        gap: 12px;
        background: #FBFBFB;
        border-radius: 8px;
        width: 544px;

        @media (max-width:1200px) {
            width: auto;
        }

        .title {
            span {
                font-style: normal;
                font-weight: 700;
                font-size: 20px;
                color: #0D2338;
            }

            width: 100%;
            display: flex;
            justify-content: space-between;
        }

        .pays {
            width: 98%;

            .select {
                background: rgba(13, 35, 56, 0.2);
            }

            div {
                cursor: pointer;
                margin: 5px 0;
                padding: 12px;
                gap: 10px;
                background: #FBFBFB;
                border: 1px solid #0D2338;
                border-radius: 8px;
                display: flex;
                align-items: center;

                span {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    color: #0D2338;
                }
            }
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        position: fixed;
        top: 30%;
        padding: 24px;
        gap: 12px;
        background: #FBFBFB;
        border-radius: 8px;
        width: 400px;

        .Header {
            display: flex;
            justify-content: center;

            img {
                margin-right: 10px;
            }

            span {
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                color: #0D2338;
            }
        }

        p {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            color: #2D2D2F;
            margin: 0
        }

        .btns {
            display: flex;
            width: 100%;

            div:first-child {
                cursor: pointer;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 12px;
                gap: 10px;
                background: #FBFBFB;
                border: 1px solid #0D2338;
                border-radius: 8px;
                margin-right: 12px;

                span {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    color: #0D2338;
                }
            }

            div {
                cursor: pointer;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 12px;
                width: 100%;
                gap: 10px;
                background: #F4D06F;
                border-radius: 8px;

                span {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    color: #0D2338;
                }

            }
        }
    }

    .form-input {
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: flex-start !important;
        margin-right: 10px;

        div {
            border: none !important;
        }

        @media (max-width:1200px) {
            align-items: center;
        }

        p {
            color: #0D2338;
            margin-bottom: 3px !important;
            font-weight: bold;
        }

        input {
            width: 100%;
            height: 50px;
            border-radius: 8px;
            outline: none;
            text-decoration: none;
            border: none;
            padding-left: 10px;
        }
    }
}

.hidden {
    display: none;
}