.dash {
    // width: 100vw;
    width: 100%;
    display: flex;
    position: relative;

    @media (max-width:1209px) {
        position: absolute;
    }

    .soporte {
        position: fixed;
        right: 2%;
        bottom: 2%;
        cursor: pointer;
    }

    .PanelInversions {
        .bodyI {
            margin-top: 30px;

            justify-content: space-between;

            .card-row {
                list-style: none;
                // display: flex;
                // height: 100vh;
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                margin-bottom: 20px;
                grid-auto-rows: 280px;
            }

            .card-row2 {
                margin-top: 300px;
            }

            .card2 {
                background: url('../../assets/img/svg/backMoney1.svg') #ebf5c9;
            }

            .card3 {
                background: url('../../assets/img/svg/backMoney2.svg') #c7e4f5;
            }

            .card4 {
                background: url('../../assets/img/svg/backMoney4.svg') #d8dbdd;
            }

            .card5 {
                background: url('../../assets/img/svg/backMoney5.svg') #98D2A9;
            }

            .card6 {
                background: url('../../assets/img/svg/backMoney6.svg') #F4D06F;
            }

            .card1 {
                background: url('../../assets/img/svg/backMoney.svg') #FCF0D2;
            }

            .card {
                margin: 7%;
                margin-bottom: 10px;
                cursor: pointer;
            }



            .card-inner {
                width: 100%;
                height: 100%;
                position: relative;
                transform-style: preserve-3d;
                transition: transform 0.5s linear;
            }

            .card:hover .card-inner {
                transform: rotateY(180deg);
            }

            .card-front,
            .card-back {
                padding: 10px;
                width: 100%;
                height: 100%;
                position: absolute;
                backface-visibility: hidden;
            }

            .card-front {
                border-radius: 8px;
                background-repeat: no-repeat;
                background-position: 100% 45%;
                transform: rotateY(0deg);

                .title {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 20px;
                    color: #0D2338;
                }

                .text {
                    margin-top: 16px;

                    h6 {
                        margin: 0;
                        font-style: normal;
                        font-weight: 700;
                        font-size: 20px;
                        color: #0D2338;
                    }

                    span {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        color: #6E5E32;
                    }
                }
            }

            .card-back {

                border-radius: 8px;
                background-repeat: no-repeat;
                background-position: 0 45%;
                transform: rotateY(180deg);

                .title {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 20px;
                    color: #0D2338;
                }

                .text {
                    margin-top: 16px;

                    h6 {
                        margin: 0;
                        font-style: normal;
                        font-weight: 700;
                        font-size: 20px;
                        color: #0D2338;
                    }

                    span {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        color: #6E5E32;
                    }
                }

                .btn {
                    background-color: #FBFBFB;
                    border-radius: 8px;
                    margin-top: 10px;
                    width: 60%;
                    padding: 5px;
                    display: flex;
                    justify-content: center;
                    border: 1px solid black;

                    span {
                        font-style: normal;
                        font-weight: 500;
                        font-size: 18px;
                        color: black;
                    }
                }
            }
        }
    }
}

.contratos,
.movimientos {
    padding: 20px 0;

    .search {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (max-width:1200px) {
            display: block;
        }

        .inputSearch {
            position: relative;
            width: 70%;
            @media (max-width:1200px) {
                width: auto;
            }
    

            img {
                position: absolute;
                top: 15px;
                right: -30px;
            }

            input {
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 15px 12px;
                padding-right: 30px;
                gap: 10px;
                background: #FBFBFB;
                border-radius: 8px;
            }
        }

        .btnFiltro {
            margin: 10px 0 10px 10px;
            width: 20%;
            border-radius: 15px;
            border: 1px solid #fff;
            cursor: pointer;
            padding: 15px;

            img {
                margin-right: 10px;
            }

            span {
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                color: #FBFBFB;
            }
        }
    }

    .filtros {
        display: flex;
        margin-top: 28px;

        @media (max-width: 1200px) {
            margin-top: 0;
            display: block;
            justify-content: center;
        }

        .espacio-input {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            position: relative;
            width: 100%;
            margin-right: 10px;

            @media (max-width:1200px) {
                align-items: center;
            }

            p {
                margin-bottom: 3px !important;
            }

            .input {
                width: 100%;
                height: 50px;
                border-radius: 8px;
                outline: none;
                text-decoration: none;
                border: none;
                padding-left: 10px;
            }
        }
    }

    .table {
        width: 100%;
        height: 60vh;
        margin-top: 28px;
        overflow-x: auto;


        .header {
            display: flex;
            justify-content: space-between;
            padding: 12px;
            gap: 8px;
            border-radius: 8px;

            div {
                width: 25%;

                span {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    color: #FFFFFF;
                }
            }
        }

        .fila {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 12px;
            border-bottom: 1px solid #FFF;

            div {
                width: 25%;
                display: flex;
                align-items: center;

                .dolar {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    color: #B2BBC6;
                    margin-left: 5px;
                }

                span {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 21px;
                    color: #FFFFFF;

                    width: 230px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }

                img {
                    margin-right: 5px;
                }

                .sub {
                    margin-left: 27px;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    text-decoration-line: underline;
                    font-feature-settings: 'calt' off;
                    color: #7FC5ED;
                    cursor: pointer;
                }
            }
        }
    }

    .table-container {
        overflow-x: auto;
    }

    .details-column {
        position: relative;
    }

    .toggle-details-btn {
        display: block;
        width: 100%;
        padding: 8px;
        background-color: #f9f9f9;
        text-align: center;
        cursor: pointer;
    }

    .details-content {
        display: none;
        padding: 8px;
        background-color: #f9f9f9;
    }

    @media (max-width: 767px) {
        table {
            display: block;
            width: 100%;
        }

        thead,
        tbody,
        th,
        td,
        tr {
            display: block;
        }

        th,
        td {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .table-container th,
        .table-container td {
            width: 100%;
        }

        .details-column {
            width: 100%;
        }

        .toggle-details-btn {
            margin-bottom: 8px;
        }

        .details-content {
            display: none;
            padding: 8px;
            background-color: #f9f9f9;
        }

        .show-details .details-content {
            display: block;
        }
    }
}

.inversions {
    display: flex;
    margin-top: 28px;
    justify-content: space-between;

    .itemI {
        span {
            cursor: pointer;
            margin-right: 32px;
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 21px;
            color: #FBFBFB;
            border-bottom: 3px solid #eccf82;
            padding-bottom: 5px;
        }
    }

    .item {
        span {
            cursor: pointer;
            font-style: normal;
            margin-right: 32px;
            font-weight: 500;
            font-size: 18px;
            color: #FBFBFB;
        }
    }
}

.Factura {
    flex-direction: column;
    background-color: #334455;
    padding: 53px 188px;
    height: 100vh;

    .headerF {
        display: flex;
        margin-top: 41px;
        justify-content: space-between;

        span {
            font-style: normal;
            font-weight: 700;
            font-size: 32px;
            color: #FFFFFF;
        }

        div {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 12px;
            gap: 10px;
            background: #FBFBFB;
            border: 1px solid #0D2338;
            border-radius: 8px;
            cursor: pointer;

            span {
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                color: #0D2338;
            }
        }
    }

    .bodyF {
        margin: 46px 0;
        display: flex;
        justify-content: center;
    }
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    // justify-items: center;
    // width: 100%;
    gap: 20px;
}

.colum-right {
    display: flex;
    flex-direction: column;
    align-items: end;
}

.rose-pastel {
    background-color: #fdcae1;
}

.blue-pastel {
    background-color: #84b6f4;
}

.green-pastel {
    background-color: #77dd77;
}

.yellow-pastel {
    background-color: #fdfd96;
}

.red-pastel {
    background-color: #ff6961;
}

.diamond {
    background-image: url('../../assets/img/svg/backMoney1.svg');
}

.graph {
    background-image: url('../../assets/img/svg/backMoney2.svg');
}

.btc {
    background-image: url('../../assets/img/svg/backMoney4.svg');
}

.line-dollar {
    background-image: url('../../assets/img/svg/backMoney5.svg');
}

.global-dollar {
    background-image: url('../../assets/img/svg/backMoney6.svg');
}

.circle-dollar {
    background-image: url('../../assets/img/svg/backMoney.svg');
}