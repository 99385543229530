.titulo-2 {
    font-size: 18px;
    width: 400px;
    text-align: left;
}

.left-column-login {
    width: 50%;

    @media (max-width: 768px) {
        display: none !important;
    }

}

.right-column-login {
    width: 50%;
    padding: 50px;

    @media (max-width: 768px) {
        width: 100% !important;
    }
}

.register-label {
    margin-top: 30px;
    align-self: center;

    span:first-child {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        font-feature-settings: 'calt' off;
        color: #FBFBFB;
        margin-right: 4px;
    }

    span:last-child {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        text-decoration-line: underline;
        font-feature-settings: 'calt' off;
        color: #7FC5ED;
        cursor: pointer;
    }
}

.title-login {
    width: 440px;
    color: #FFF;
    text-align: left;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}