.input-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
        align-self: flex-start;
    }

    .input-body {
        width: 100%;
        display: flex;
        flex-direction: row;
        position: relative;

        .cab-input {
            padding: 0 10px;
            width: 100%;
            height: 50px;
            border-radius: 8px;
            outline: none;
            text-decoration: none;
            border: none;

            &.error {
                border: 2px solid #B55348;
                color: #B55348;
            }

            &.success {
                border: 2px solid #B7EB06;
            }
        }

        .input-icon {
            cursor: pointer;
            position: absolute;
            object-fit: contain;
            left: unset;
            right: 12px;
            top: 50%;
            transform: translateY(-50%);
        }

        .input-icon-left {
            padding-left: 50px;
        }

        .icon-left {
            position: absolute;
            left: 10px; // Ajusta la posición del icono a tu preferencia
            top: 47%;
            transform: translateY(-50%);
            font-size: 18px; // Tamaño del icono
        }


    }

    .text-error {
        color: #B55348;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}