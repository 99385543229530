.custom-dropdown {
    height: 50px;
    border-radius: 8px;
    outline: none;
    text-decoration: none;
    position: relative;
    font-size: 16px;
    cursor: pointer;
    background-color: #fff;

    select {
        padding: 8px;
        appearance: none;
        border: none;
        background: transparent;
        width: 95%;
        height: 100%;
        font-size: 14px;
        cursor: pointer;
        position: relative;
        outline: none;
        text-decoration: none;

        /* Establecer una imagen de fondo personalizada */
        background-image: url('../../assets/img/svg/arrowSelect.svg');
        /* Ruta a tu imagen SVG o PNG */
        background-size: 14px;
        /* Tamaño de la imagen */
        background-repeat: no-repeat;
        background-position: right center;
    }
}

.type-phone {
    width: 75px !important;
    max-width: 75px;
}