.calendario-input {
  height: 50px;
  border-radius: 8px;
  outline: none;
  text-decoration: none;
  position: relative;
  font-size: 15px;
  cursor: pointer;
  background-color: #fff;

  .react-date-picker__wrapper {
    border: none;
  }

  .react-datepicker__input-container {
    width: 100%;
    height: 100%;
  }

  input {
    padding: 8px;
    appearance: none;
    border: none;
    background: transparent;
    width: 95%;
    height: 70%;
    font-size: 14px;
    cursor: pointer;
    position: relative;
    outline: none;
    text-decoration: none;

  }
}

.text-error {
  color: #B55348 !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.react-date-picker {
  width: 100% !important;
  height: 100%;
}