.InfoC {
    background-color: #0d2338;
    padding: 50px 130px;
    height: 100vh;

    @media (max-width:1200px) {
        padding: 50px 0;
    }

    .back {
        display: flex;
        cursor: pointer;
        // margin-left: 20px;

        span {
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            color: #FFFFFF;
        }
    }

    .header {
        display: flex;
        justify-content: space-between;
        margin-top: 41px;

        @media (max-width:1200px) {
            display: block;
            padding-left: 20px;
            padding-right: 20px;
        }

        h1 {
            margin: 0;
            font-style: normal;
            font-weight: 700;
            font-size: 32px;
            color: #FFFFFF;

            @media (max-width:1200px) {
                font-size: 22px;
            }

        }

        .btnR {
            padding: 12px;
            background: #FBFBFB;
            border: 1px solid #0D2338;
            border-radius: 8px;
            display: flex;
            align-items: center;
            cursor: pointer;
            width: 200px;

            span {
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                color: #0D2338;
                margin-left: 10px;
                width: 230px;
            }
        }

        @media (max-width:1200px) {
            .btnMore {
                padding: 12px;
                font-size: 30px;
                font-weight: bold;
                background: #FBFBFB;
                border: 1px solid #0D2338;
                border-radius: 8px;
                display: flex;
                align-items: center;
                cursor: pointer;
                width: 25px;
                justify-content: center;
            }
        }

        .buttons-header {
            display: flex;
            justify-content: end;
            margin-top: 10px;
        }

        .dropdown-list {
            display: none;
            position: absolute;
            top: 100%;
            left: -442%;
            width: 234px;
            background-color: #fff;
            color: #0D2338;
            padding: 20px;
            margin-top: 10px;
            list-style: none;
            z-index: 100;
            border-radius: 8px;
            font-size: 18px;
            font-weight: 700;
            line-height: 32.1px;

            li {
                padding: 5px;
            }

            li:hover {
                background-color: #FCF0D2;
                cursor: pointer;
            }
        }

        .btnMore.active+.dropdown-list {
            display: block;
        }
    }

    .body {
        display: flex;
        margin-top: 95px;
        justify-content: space-between;
        gap: 10px;

        @media (max-width:1200px) {
            margin-top: 30px;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }

        .cardInfo {
            flex-grow: 1;
            flex-basis: 0;
            width: 80%;
            border-radius: 8px;
            padding: 28px;
            background-repeat: no-repeat;
            background-position: 100% 45%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            h6 {
                margin: 0;
                font-style: normal;
                font-weight: 700;
                font-size: 20px;
                color: #0D2338;
            }

            div {
                display: flex;
                flex-direction: column;

                span:first-child {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    color: #0D2338;
                }

                span:last-child {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    color: #6E5E32;
                }

                .state {
                    display: flex;
                    flex-direction: row;
                    margin: 0;
                    align-items: center;

                    img {
                        width: 12px;
                        height: 12px;
                        margin-right: 10px;
                    }
                }
            }
        }

        .cardsI {
            align-self: center;
            flex-grow: 1;
            text-align: -webkit-center;
            flex-basis: 0;

            @media (max-width:1200px) {
                margin-top: 20px;
                text-align: -webkit-center;
                width: 100%;
            }

            .cardI {
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding: 12px;
                width: 282px;
                background: #47586E;
                border-radius: 8px;

                @media (max-width:1200px) {
                    width: 80%;
                }

                .title {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    span {
                        font-style: normal;
                        font-weight: 700;
                        font-size: 16px;
                        color: #FBFBFB;
                    }

                    div {
                        display: flex;
                        align-items: center;
                    }
                }

                .money {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 20px;
                    text-align: center;
                    color: #FFFFFF;
                    margin: 5px 0;
                }

                span:last-child {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 16px;
                    text-align: center;
                    font-feature-settings: 'calt' off;
                    color: #FFFFFF;
                    margin: 5px 0;
                }
            }

            .cardI:last-child {
                .title {
                    width: 75%;
                }
            }

            .cardIMid {
                margin: 8px 0;
            }
        }

        .btnsI {
            align-items: center;
            flex-grow: 1;
            flex-basis: 0;

            @media (max-width:1200px) {
                display: none;
            }

            padding: 15px;
            border: 1px solid #47586E;
            border-radius: 8px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .btnI {
                cursor: pointer;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 12px;
                width: 212px;
                background: #47586E;
                border: 4px solid #F4D06F;
                border-radius: 8px;

                span {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    color: #FFFFFF;
                }
            }
        }
    }
}

.FacturaContract {
    padding: 50px 230px;
    min-height: 100vh;

    @media (max-width:1200px) {
        padding: 50px 30px;
    }

    .title {
        margin-top: 38px;

        @media (max-width:1200px) {
            width: 50%;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        span {
            font-style: normal;
            font-weight: 700;
            font-size: 32px;
            color: #FFFFFF;
        }
    }
}

.soporte {
    position: fixed;
    right: 300px;
    bottom: 60px;
    cursor: pointer;
}

.solicitud {
    padding: 53px 190px;
    min-height: 100%;

    @media (max-width:1200px) {
        padding: 0px;
        margin-left: 20px;
        width: 100%;
    }

    .title {
        margin-top: 65px;

        span {
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            color: #FBFBFB;
        }
    }

    .text {
        margin-top: 24px;

        span {
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            color: #FBFBFB;
        }
    }
}

.MiInversions {
    margin-top: 40px;

    .card {
        background: #FCF0D2;
        cursor: pointer;
        width: 400px;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        h6 {
            margin: 5px 0;
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            color: #0D2338;
        }

        div {
            display: flex;
            flex-direction: column;
            margin: 10px 0;

            span:first-child {
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                color: #0D2338;
            }

            span:last-child {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                color: #6E5E32;
            }

            .state {
                display: flex;
                flex-direction: row;
                margin: 0;
                align-items: center;

                img {
                    width: 12px;
                    height: 12px;
                    margin-right: 10px;
                }
            }
        }

        .cardF {
            padding: 28px;
            background: url('../../assets/img/svg/backMoney.svg') #FCF0D2;
            background-repeat: no-repeat;
            background-position: 100% 45%;
            transition: opacity 300ms ease-in;
        }

        .cardB {
            padding: 28px;
            background: url('../../assets/img/svg/backMoneyBack1.svg') #FCF0D2;
            background-repeat: no-repeat;
            background-position: 0 45%;
            padding-left: 40%;
            transition: opacity 300ms ease-in;

            h6 {}

            .btn {
                background-color: #FBFBFB;
                border-radius: 8px;
                margin-top: 10px;
                width: 100%;
                padding: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid black;

                span {
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;
                    color: black;
                }
            }
        }
    }
}

.espacio-input-form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    width: 100%;

    p {
      margin-bottom: 3px !important;
    }

    .titulo-2 {
      font-size: 18px;
      width: 400px;
    }

    .input {
      width: 100%;
      // max-width: 400px;
      height: 50px;

      border-radius: 8px;
      outline: none;
      text-decoration: none;
      border: none;
      margin-top: 10px;
      padding-left: 10px;
    }

    .input-dolar {
      padding-left: 50px;
      width: 90%;
    }

    .dolar {
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      color: #0D2338;
      position: absolute;
      top: 64px;
      left: 10px;
      margin: auto;
    }

    .ojo {
      position: absolute;
      right: 15px;
      bottom: 20px;
      width: 16px;
      object-fit: contain;
      cursor: pointer;
    }
  }

  select.input.selectInput {
    width: 412px;
  }

  .rose-pastel {
    background-color: #fdcae1;
}

.blue-pastel {
    background-color: #84b6f4;
}

.green-pastel {
    background-color: #77dd77;
}

.yellow-pastel {
    background-color: #fdfd96;
}

.red-pastel {
    background-color: #ff6961;
}