.inbox {
    min-height: 100vh;
    width: 100%;
    background: #0d2338;
    padding: 40px 90px;

    @media (max-width:1200px) {
        position: absolute;
        width: 80%;
        padding: 40px 40px;
    }

    .back {
        display: flex;
        cursor: pointer;

        span {
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            color: #FFFFFF;
        }
    }

    .title {
        span {
            font-style: normal;
            font-weight: 700;
            font-size: 24px;
            color: #FFFFFF;
        }
    }

    .search {
        display: flex;
        justify-content: space-between;
        margin: 40px 0;

        .inputSearch {
            width: 440px;
            position: relative;

            img {
                position: absolute;
                top: 15px;
                right: -30px;
            }

            input {
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 15px 12px;
                padding-right: 30px;
                gap: 10px;
                background: #FBFBFB;
                border-radius: 8px;
            }
        }
    }

    .inbox-table {
        width: 100%;

        .inbox-row {
            cursor: pointer;
            display: flex;
            width: 100%;
            justify-content: space-between;
            border-bottom: 1px solid #FFF;
            padding: 15px 10px;

            span {
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                color: #FFFFFF;
            }
        }

        .first-row {
            border-top: 1px solid #FFF;
        }

        .inbox-row-select {
            display: flex;
            cursor: pointer;
            width: 100%;
            justify-content: space-between;
            border-bottom: 1px solid #FFF;
            padding: 15px 10px;
            background: #B4BBC1;

            span:last-child,
            span:first-child {
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                color: #0D2338;
            }

            span {
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                color: #0D2338;
            }
        }
    }

    .inbox-body {
        width: 828px;
        margin-top: 45px;
        height: 451px;
        border: 1px solid #FBFBFB;
        border-radius: 8px;
        overflow-y: auto;

        @media (max-width:1200px) {
            width: auto;
        }


        .detail-header {
            padding: 40px 30px;
            display: flex;
            justify-content: space-between;

            span {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                color: #FFFFFF;
            }

            .section {
                display: flex;

                div {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    margin-left: 10px;

                    h6 {
                        margin: 0;
                        font-style: normal;
                        font-weight: 700;
                        font-size: 18px;
                        color: #FFFFFF;
                    }

                    p {
                        margin: 0;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        color: #FFFFFF;
                    }
                }
            }
        }

        .detail-text {
            margin: 0 82px;

            span {
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                color: #FFFFFF;
            }

            p {
                line-height: 1.6;
                letter-spacing: 0.5px;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                color: #FFFFFF;
            }
        }
        @media (max-width:1200px) {
            .detail-text {
                margin: 0 20px;
            }
        }
    }
}

.profile-img {
    height: 40px;
    width: 40px;
    border-radius: 50%;
}