.cambiarPass{
    background: #0d2338;
    min-height: 100vh;
    padding: 50px 190px;
    .back{
        display: flex;
        cursor: pointer;
        span{
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            color: #FFFFFF;
        }
    }
    .bodyPass{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        .containerPass{
            width: 408px;
            margin-top: 24px;
            h1{
                margin:24px 0;
                font-style: normal;
                font-weight: 700;
                font-size: 24px;
                color: #FBFBFB;
            }
            p{
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                color: #FBFBFB;     
            }
            .formulario-inicio{
                margin:0 !important;
                .ojo{
                    top:61px !important;
                }
                .texto-error{
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    color: #B55348;
                }
            }
        }
    }
}

.general-body {
    width: 100%;
    height: 100vh;
    overflow-x: hidden;
    background-color: #0d2338;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.componente-mitad {
    width: 50%;
    height: 100vh;
    position: relative;
    display: flex;
    align-items: center;

    @media (max-width: 1200px) {
        width: 100% !important;
        justify-content: center;
    }

    p {
        color: white;
    }

    .titulo-1 {
        font-size: 24px;
    }

    .titulo-2 {
        font-size: 18px;
        width: 600px;
    }

    .vector-fondo {
        position: absolute;
        top: 0;
        left: 0px;
        height: 100%;
        width: 100%;
    }

    .espacio-infoPass {
        position: absolute;

        @media (max-width: 1200px) {
            display: none;
        }
    }

    .formulario-codigo {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;

        @media (max-width: 1200px) {
            justify-content: center;
            align-items: center;
        }

        .titulo-2 {
            font-size: 18px;
            width: 400px;
        }

        .texto-error {
            font-size: 14px;
            color: #B55348;
            margin-left: 10px;
        }

        .espacio-titulo {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            width: 100%;
            margin-top: 250px;

            @media (max-width: 1200px) {
                justify-content: center;
                align-items: start;
                margin-top: 50px;
            }

            .titulo-img {
                width: 300px;
                height: 59px;
            }

            .logo-img {
                width: 72px;
                height: 63px;
            }

        }

        .espacio-input-codigo {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            position: relative;

            p {
                margin-bottom: 3px !important;
            }

            .input {
                width: 72px;
                height: 72px;
                border-radius: 8px;
                outline: none;
                text-decoration: none;
                font-size: 32px;
                border: none;
                text-align: center;
                margin: 10px;
            }

            .input-error {
                width: 72px;
                height: 72px;
                border-radius: 8px;
                outline: none;
                text-decoration: none;
                font-size: 32px;
                border: 2px solid #B55348;
                text-align: center;
                margin: 10px;
            }

            .input-success {
                width: 72px;
                height: 72px;
                border-radius: 8px;
                outline: none;
                text-decoration: none;
                font-size: 32px;
                border: 2px solid #B7EB06;
                text-align: center;
                margin: 10px;
            }
        }

        .boton-continuar-disabled {
            width: 470px;
            height: 42px;
            background-color: #B4BBC1;
            color: #777778;
            font-weight: bold;
            font-size: 16px;
            margin-top: 24px;
            margin-left: 10px;
            outline: none;
            text-decoration: none;
            border-radius: 8px;
            padding: 0;
            border: none;
            cursor: pointer;
        }

        .boton-continuar-enabled {
            width: 470px;
            height: 42px;
            background-color: #F4D06F;
            color: #0D2338;
            font-weight: bold;
            font-size: 16px;
            margin-top: 24px;
            margin-left: 10px;
            outline: none;
            text-decoration: none;
            border-radius: 8px;
            padding: 0;
            border: none;
            cursor: pointer;
        }

        .boton-reenviar-codigo {
            width: 470px;
            height: 50px;
            background-color: #FBFBFB;
            color: #0D2338;
            font-weight: bold;
            font-size: 16px;
            margin-top: 24px;
            margin-left: 10px;
            outline: none;
            text-decoration: none;
            border-radius: 8px;
            padding: 0;
            border: none;
        }
    }
}

.formulario-inicio {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 200px;
    width: 100%;

    @media (max-width: 1200px) {
        margin-left: 40px;
        justify-content: center;
        align-items: center;
    }

    .titulo-2 {
        font-size: 18px;
        width: 400px;
    }

    .espacio-titulo {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;

        .titulo-img {
            width: 300px;
            height: 59px;
        }

        .logo-img {
            width: 72px;
            height: 63px;
        }

    }

    .espacio-subtitulo {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        cursor: pointer;

        .flecha-atras {
            width: 24px;
            height: 24px;
            margin: 10px;
        }

    }

    .espacio-input {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        position: relative;

        p {
            margin-bottom: 3px !important;
        }

        .titulo-2 {
            font-size: 18px;
            width: 400px;
        }

        .titulo-2-error {
            font-size: 18px;
            width: 400px;
            color: #B55348;
        }

        .texto-error {
            font-size: 14px;
            width: 400px;
            color: #B55348;
        }

        .input {
            width: 400px;
            height: 50px;
            border-radius: 8px;
            outline: none;
            text-decoration: none;
            border: none;
            padding-left: 10px;
        }

        .input-error {
            width: 400px;
            height: 50px;
            border-radius: 8px;
            outline: none;
            text-decoration: none;
            border: 2px solid #B55348;
            color: #B55348;
            padding-left: 10px;
        }

        .ojo {
            position: absolute;
            right: 15px;
            bottom: 20px;
            width: 16px;
            object-fit: contain;
            cursor: pointer;
        }

        .ojo-error {
            position: absolute;
            right: 15px;
            bottom: 78px;
            width: 16px;
            object-fit: contain;
            cursor: pointer;
        }
    }

    .boton-continuar-disabled {
        width: 408px;
        height: 42px;
        background-color: #B4BBC1;
        color: #777778;
        font-weight: bold;
        font-size: 16px;
        margin-top: 24px;
        outline: none;
        text-decoration: none;
        border-radius: 8px;
        padding: 0;
        border: none;
        cursor: pointer;
    }

    .boton-continuar-enabled {
        width: 408px;
        height: 42px;
        background-color: #F4D06F;
        color: #0D2338;
        font-weight: bold;
        font-size: 16px;
        margin-top: 24px;
        outline: none;
        text-decoration: none;
        border-radius: 8px;
        padding: 0;
        border: none;
        cursor: pointer;
    }

    .olvidar-pass {
        font-size: 16px;
        color: #B7EB06;
        font-weight: 700;
        cursor: pointer;
    }

}