.button {
    width: 470px;
    height: 42px;
    font-weight: bold;
    font-size: 16px;
    margin-top: 24px;
    outline: none;
    text-decoration: none;
    border-radius: 8px;
    padding: 0;
    border: none;
    cursor: pointer;
    color: #0D2338;

    &.disabled {
        background-color: #B4BBC1 !important;
        color: #777778 !important;
    }

    &.primary {
        background-color: #F4D06F;
    }

    .secondary {
        background: #FBFBFB;
    }

    &.big {
        width: 100% !important;
    }
}
