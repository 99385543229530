.espacio-input-codigo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;

    p {
        margin-bottom: 3px !important;
    }

    .input_otp {
        width: 72px;
        height: 72px;
        border-radius: 8px;
        outline: none;
        text-decoration: none;
        font-size: 32px;
        border: none;
        text-align: center;
        margin: 10px;
    }

    .input_error_otp {
        width: 72px;
        height: 72px;
        border-radius: 8px;
        outline: none;
        text-decoration: none;
        font-size: 32px;
        border: 2px solid #B55348;
        text-align: center;
        margin: 10px;
    }

    .input_success_otp {
        width: 72px;
        height: 72px;
        border-radius: 8px;
        outline: none;
        text-decoration: none;
        font-size: 32px;
        border: 2px solid #B7EB06;
        text-align: center;
        margin: 10px;
    }
}

.text-error {
    color: #B55348;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}