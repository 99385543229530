.mobil {
    @media (min-width:1201px) {
        display: none;
    }

    @media (max-width:1200px) {
        padding: 20px;

        .title {
            margin-bottom: 20px;

            span {
                width: 460px;
                display: block;
                font-style: normal;
                font-weight: bold;
                font-size: 28px;
                color: #FBFBFB;
            }
        }

        .description {

            span {
                width: 460px;
                display: block;
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                color: #FBFBFB;
            }
        }

        .espacio-input {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            position: relative;
            width: 100%;

            p {
                margin-bottom: 3px !important;
            }

            .titulo-2 {
                font-size: 18px;
                width: 400px;
                color: #fff;
            }

            .input {
                width: 90%;
                height: 50px;
                font-size: 18px;
                border-radius: 8px;
                outline: none;
                text-decoration: none;
                border: none;
                padding-left: 50px;
            }

            .dolar {
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                color: #0D2338;
                position: absolute;
                top: 54px;
                left: 10px;
            }
        }

        .banks {
            margin-top: 40px;


            .bank {
                padding: 28px;
                gap: 12px;

                // background: #FCF0D2;
                border-radius: 8px;
                margin-bottom: 12px;

                .bankTitle {
                    display: flex;
                    align-items: center;

                    img {
                        margin-right: 10px;
                    }

                    span {
                        font-style: normal;
                        font-weight: 700;
                        font-size: 20px;
                        color: #0D2338;
                    }
                }

                .bankD {
                    margin-top: 12px;
                    margin-left: 40px;

                    span {
                        font-style: normal;
                        font-weight: 700;
                        font-size: 16px;
                        color: #0D2338;
                    }

                    p {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        color: #6E5E32;
                        margin-top: 5px;
                    }
                }
            }
        }

        .btnSend {
            padding: 12px;
            cursor: pointer !important;
            margin-top: 20px;
            gap: 10px;
            width: 95%;
            background: #F4D06F;
            border-radius: 8px;
            display: flex;
            justify-content: center;

            span {
                font-style: normal;
                font-weight: 700;
                font-size: 16px;
                color: #0D2338;
            }
        }

        .btnDisable {
            cursor: pointer;
            background: #B4BBC1;

            span {
                color: #777778;
            }
        }
    }
}

.desktop {
    @media (max-width:1200px) {
        display: none;
    }

    @media (min-width:1201px) {

        .balanceBank {
            background: #0d2338;
            padding-left: 188px;
            padding-top: 53px;
            display: flex;
            min-height: 100vh;

            @media (max-width: 1200px) {
                padding: 0;
            }

            .left {
                .textFirma {
                    position: absolute;
                }

                .titles {
                    position: absolute;
                }

                .title {
                    display: flex;
                    margin-top: 41px;

                    img {
                        margin-right: 14px;
                    }

                    span {
                        font-style: normal;
                        font-weight: 700;
                        font-size: 32px;
                        color: #FFFFFF;
                    }
                }

                .description {
                    margin: 0;
                    margin-top: 18px;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;
                    color: #FFFFFF;
                }

                .banks {
                    margin-top: 150px;

                    .bank {
                        padding: 28px;
                        gap: 12px;
                        width: 302px;

                        // background: #FCF0D2;
                        border-radius: 8px;
                        margin-bottom: 12px;

                        .bankTitle {
                            display: flex;
                            align-items: center;

                            img {
                                margin-right: 10px;
                                margin-left: -10px;
                            }

                            span {
                                font-style: normal;
                                font-weight: 700;
                                font-size: 20px;
                                color: #0D2338;
                            }
                        }

                        .bankD {
                            margin-top: 12px;
                            margin-left: 40px;

                            span {
                                font-style: normal;
                                font-weight: 700;
                                font-size: 16px;
                                color: #0D2338;
                            }

                            p {
                                font-style: normal;
                                font-weight: 400;
                                font-size: 14px;
                                color: #6E5E32;
                                margin-top: 5px;
                            }
                        }
                    }
                }
            }

            .right {
                padding-left: 50px;
                margin-top: 260px;
                width: 100%;

                .title {
                    span {
                        width: 460px;
                        display: block;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 18px;
                        color: #FBFBFB;
                    }
                }

                .espacio-input {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    position: relative;
                    width: 100%;

                    p {
                        margin-bottom: 3px !important;
                    }

                    .titulo-2 {
                        font-size: 18px;
                        width: 400px;
                        color: #fff;
                    }

                    .input {
                        width: 420px;
                        height: 50px;
                        border-radius: 8px;
                        outline: none;
                        text-decoration: none;
                        border: none;
                        padding-left: 50px;
                    }

                    .dolar {
                        font-style: normal;
                        font-weight: 700;
                        font-size: 16px;
                        color: #0D2338;
                        position: absolute;
                        top: 57%;
                        left: 10px;
                    }

                    .ojo {
                        position: absolute;
                        right: 15px;
                        bottom: 20px;
                        width: 16px;
                        object-fit: contain;
                        cursor: pointer;
                    }
                }

                .btnUpload {
                    cursor: pointer;
                    width: 460px;
                    border-radius: 10px;
                    border: 1px solid #FFF;
                    padding: 12px 0;
                    display: flex;
                    justify-content: center;


                    input {
                        position: absolute;
                        visibility: hidden;
                    }

                    span {
                        font-style: normal;
                        font-weight: 700;
                        font-size: 16px;
                        color: #FBFBFB;
                        margin-left: 14px;
                    }
                }

                .fileUpload {
                    padding: 12px;
                    margin-top: 50px;
                    gap: 10px;
                    width: 440px;
                    background: #B4BBC1;
                    border: 1px solid #0D2338;
                    border-radius: 8px;
                    display: flex;
                    justify-content: space-between;

                    div {
                        cursor: pointer;

                        img {
                            margin-right: 16px;
                            cursor: pointer;
                        }

                        span {
                            font-style: normal;
                            font-weight: 700;
                            font-size: 16px;
                            color: #0D2338;
                        }
                    }
                }

                .btnSend {
                    padding: 12px;
                    cursor: pointer !important;
                    margin-top: 40px;
                    gap: 10px;
                    width: 450px;
                    background: #F4D06F;
                    border-radius: 8px;
                    display: flex;
                    justify-content: center;

                    span {
                        font-style: normal;
                        font-weight: 700;
                        font-size: 16px;
                        color: #0D2338;
                    }
                }

                .btnDisable {
                    cursor: pointer;
                    background: #B4BBC1;

                    span {
                        color: #777778;
                    }
                }
            }
        }

    }
}

.back {
    display: flex;
    cursor: pointer;
    margin-bottom: 20px;

    img {
        margin-right: 10px;
        width: 24px;
        height: 24px;
    }

    span {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        color: #FFFFFF;
        margin-top: 1px;
    }
}

.elseFile {
    display: flex;
    align-items: center;
    margin-top: 12px;
    cursor: pointer;

    span {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        color: #FBFBFB;
        margin-left: 12px;
    }

    input {
        visibility: hidden;
    }
}

.modal .content {
    top: 28% !important;
}

.containerCon {
    height: 100vh;
    display: block;
    
    @media (max-width:1200px) {

        padding: 30px;
    }
    
    .titleSeccion {
        margin-bottom: 20px;

        span {
            font-style: normal;
            font-weight: 700;
            font-size: 32px;
            color: #FFFFFF;
        }
    }
}

@media (min-width: 1301px) {
    .dolar {
        top: 62% !important;
    }
}

.contract-link {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #6E5E32;
    margin-top: 5px;
}

.crema-pastel {
    background-color: #FCF0D2;
}

.azul-claro-pastel {
    background-color: #C5EAFF;
}

.verde-limon-pastel {
    background-color: #F2FFC7;
}

.gris-pastel {
    background-color: #DBDEE1;
}

.verde-menta-pastel {
    background-color: #98D2A9;
}

.amarillo-pastel {
    background-color: #F4D06F;
}
.extra-long-word{
    text-overflow: ellipsis;
    overflow: hidden;
}
    