.notification {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    max-width: 400px;
    position: fixed;
    top: 10px;
    right: 10px;
    padding: 10px 20px;
    border-radius: 4px;   
    font-weight: bold;
    animation: slideIn 0.5s ease-out;
    z-index: 1;

    .title {
        font-weight: 700;
        font-size: 18px;
        color: #0D2338;
    }

    p {
        font-weight: 400;
        font-size: 14px;
        color: #0D2338;
    }

    &.success {
        background-color: #26C485;
    }

    &.error {
        background-color: #F4D06F;
    }

    &.info {
        background-color: #FBFBFB;
    }

    @keyframes slideIn {
        from {
            transform: translateX(100%);
        }

        to {
            transform: translateX(0);
        }
    }
}